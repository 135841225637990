import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuList: null
  },
  // getters: {
  //   menuList: state => state.menuList
  // },
  mutations: {
    SET_MenuList: (state, menuList) => { //页面参数传递格式{key:‘key’,value:‘value’}
      state.menuList = menuList;
    }
  },
  actions: {},
  modules: {}
})