<template>
  <div class="searchItem" :style="{ width: searchWidth + 'px' }">
    <div
      :style="{
        width: labelWidth + 'px',
        'justify-content': labelLocation,

        display: 'flex',
        'align-items': 'center',
      }"
    >
      <span :class="[isRequired ? 'requireField' : '']">{{ label }}</span>
    </div>
    <el-input
      :type="type"
      :placeholder="'请输入' + placeholder"
      v-if="searchType == 'input'"
      v-model="value"
      :disabled="disabled"
      :style="{ width: inputWidth + 'px' }"
    ></el-input>
    <el-select
      v-model="value"
      :placeholder="placeholder"
      v-if="searchType == 'select'"
      :style="{ width: inputWidth + 'px' }"
    >
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.label"
        :value="item.id"
      >
      </el-option>
    </el-select>

    <el-date-picker
      v-if="searchType == 'date'"
      v-model="value"
      value-format="yyyy-MM-dd"
      type="date"
      placeholder="选择日期"
      :editable="false"
      :style="{ width: inputWidth + 'px' }"
    >
    </el-date-picker>
    <el-date-picker
      v-if="searchType == 'year'"
      v-model="value"
      value-format="yyyy"
      type="year"
      :editable="false"
      :style="{ width: inputWidth + 'px' }"
      placeholder="请选择年"
    ></el-date-picker>
    <el-date-picker
      v-if="searchType == 'month'"
      v-model="value"
      value-format="yyyy-MM"
      type="month"
      :editable="false"
      :style="{ width: inputWidth + 'px' }"
      placeholder="选择月"
    >
    </el-date-picker>
  </div>
</template>
<script>
export default {
  name: "SearchInput",
  props: {
    label: "",
    placeholder: "",
    searchType: "",
    options: [],
    defaultValue: "",
    field: "",
    fieldName: "",
    level: {
      // 用来区别当前组件有没有被别的组件包裹起来
      type: String | Number,
      default: 0,
    },
    type: {
      // 用来区别当前组件有没有被别的组件包裹起来
      type: String | Number,
      default: "text",
    },
    labelWidth: {
      type: String | Number,
      default: "130",
    },
    labelLocation: {
      type: String | Number,
      default: "right",
    },
    searchWidth: {
      type: String | Number,
      default: "310",
    },
    inputWidth: {
      type: String | Number,
      default: "180",
    },
    isRequired: {
      type: Boolean | String,
      default: false,
    },
    disabled: {
      type: Boolean | String,
      default: false,
    },
  },
  data() {
    return {
      value: "",
    };
  },
  watch: {
    // 子传父
    defaultValue: {
      handler: function (newVal) {
        this.value = newVal;
      },
      deep: true,
    },
    value(newval) {
      // console.log(newval, this);
      // 说明没有被dialog包裹
      if (this.level == 0) {
        if (this.field) {
          this.$parent[this.field][this.fieldName] = newval;
        } else {
          this.$parent[this.fieldName] = newval;
        }
      } else {
        if (this.field) {
          this.$parent.$parent[this.field][this.fieldName] = newval;
        } else {
          // console.log(this.$parent.$parent.orderPerson)
          this.$parent.$parent[this.fieldName] = newval;
        }
      }
    },
  },
  mounted() {
    // 父传子
    if (this.searchType == "input" || this.searchType == "month") {
      this.value = this.defaultValue ? this.defaultValue : "";
    } else if (this.searchType == "select") {
      console.log(this.defaultValue);
      if (this.defaultValue || this.defaultValue == 0) {
        this.value = this.defaultValue;
      } else {
        this.value = "";
      }
    }
  },
  methods: {
    // blurHandle(e) {
    //   this.$emit("blurHandle", this.value);
    // },
  },
};
</script>
<style scoped>
.searchItem {
  display: flex;
  margin: 10px 10px 10px;
  /* width: 500px; */
}
.requireField {
  position: relative;
}
.requireField::after {
  content: "*";
  color: #de1e23;
  position: absolute;
  top: 50%;
  left: -0.5rem;
  transform: translateY(-50%);
}
</style>
    