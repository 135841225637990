import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    // redirect: "/auth/workBench/payRollManagement",
    redirect: "/auth",
  },
  {
    path: "/pdf",
    name: "pdf",
    component: () => import("@/views/NoAuth/pdf.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/NoAuth/Login.vue")
  },
  {
    path: '/auth',
    component: () => import("@/views/Auth/Auth.vue"),
    redirect: "/auth/workBench/payRollManagement",

    children: [{
        path: "workBench",
        name: "workBench",
        // 角色管理
        component: () => import("@/modules/workBench/workBench.vue"),
        redirect: "/auth/workBench/payRollManagement",
        children: [{
          path: "payRollManagement",
          name: "payRollManagement",
          // 角色管理
          component: () => import("@/modules/workBench/payRollManagement/payRollManagement.vue")
        }]
      },
      {
        path: "permissionSettings",
        name: "permissionSettings",
        // 角色管理
        component: () => import("@/modules/permissionSettings/permissionSettings.vue"),
        redirect: (to) => {
          let lastPath = JSON.parse(localStorage.getItem("tabsList")).filter(item => item.path == "permissionSettings")[0].children[0].path;
          return "/auth/permissionSettings/" + lastPath;
        },
        children: [{
            path: "organizationalManagement",
            name: "organizationalManagement",
            // 角色管理
            component: () => import("@/modules/permissionSettings/organizationalManagement/organizationalManagement.vue")
          },
          {
            path: "roleManagement",
            name: "roleManagement",
            // 角色管理
            component: () => import("@/modules/permissionSettings/roleManagement/roleManagement.vue")
          },
          {
            path: "UserManagement",
            name: "UserManagement",
            // 组织管理
            component: () => import("@/modules/permissionSettings/UserManagement/UserManagement.vue")
          },
        ]
      },
      {
        path: "systemSettings",
        name: "systemSettings",
        // 角色管理
        component: () => import("@/modules/systemSettings/systemSettings.vue"),
        redirect: (to) => {
          let lastPath = JSON.parse(localStorage.getItem("tabsList")).filter(item => item.path == "systemSettings")[0].children[0].path;
          return "/auth/systemSettings/" + lastPath;
        },
        // redirect: "/auth/systemSettings/classificationManagement",
        children: [{
            path: "classificationManagement",
            name: "classificationManagement",
            // 角色管理
            component: () => import("@/modules/systemSettings/classificationManagement/classificationManagement.vue")
          },
          {
            path: "nameManagement",
            name: "nameManagement",
            // 角色管理
            component: () => import("@/modules/systemSettings/nameManagement/nameManagement.vue")
          },
          {
            path: "explanationManagement",
            name: "explanationManagement",
            component: () => import("@/modules/systemSettings/explanationManagement/explanationManagement.vue")
          }
        ]
      },
    ],
  },
]


const router = new VueRouter({
  routes
});
// router.beforeEach((to, from, next) => {
//   console.log(to, 98);
//   let isLogin = localStorage.getItem("token");
//   if (isLogin) {
//     // 如果存在token 放行
//     next();
//   } else {
//     //如果用户token不存在且 当前页面就是 则放行
//     if (to.path === "/login") {
//       next();
//     } else {
//       // 当前页面不是登录页 则直接跳转到页面
//       // next("/login");
//       router.push({
//         name: "Login"
//       })
//     }
//   }
// });

export default router